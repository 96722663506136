import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    withRouter,
    PaginationParams,
    GLUtil,
    InvitationType,
    PathConfig as CommonPath,
    ResourceType,
    Propagation,
    GLAction,
    GLGlobal,
    OidcProxy,
} from "gl-commonui";
import moment from "moment";
import { PathConfig } from "@app/config/pathconfig";
import { ISchoolService, SchoolDetailModel, SchoolBillingRequest } from "@app/service/schools/index";
import { IResourceService } from "@app/service/resources/index";
import { IUserService } from "@app/service/users/index";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { MainTitle, Loading, Action, Container } from "@app/components";
import { BillingTableComponent, BillingTableProps } from "./components";
import { lazyInject, TYPES, fmtMsg, GSAdminAction, DateHelper, AdjustmentDateFormat, CONSTS } from "@app/util/index";
import { Row, Col, Modal, Select } from "gl-commonui/lib/antd-min";
import { CampusModel } from "@app/service/school/campus";
import { SchoolClassModel, ISchoolClassService } from "@app/service/class";
import { ISchoolLicenseService, UpdateAdjustmentResultCode } from "@app/service/admin/license";
import { ManualAdjustmentInfoModel } from "@app/states/school/class";
import { InputNumber, Input, message } from "antd-min";
import { RegenerateInvoice } from "../class/components/regenerate-invoice";
import { IRegionService } from "@app/service/admin/regions";
import { GridRef } from "@app/components/grid/grid";

interface LicensePageProps extends RouteComponentProps<any> { }
interface LicensePageStates {
    loading?: boolean;
    school?: SchoolDetailModel;
    schoolAdmin?: string;
    schoolTrainer?: string;
    modalVisible: boolean;
    campuses: CampusModel[];
    campusSelected: string;
    classes: SchoolClassModel[];
    selectedClass: string;
    cycleAdjustmentData: ManualAdjustmentInfoModel;
    origCycleAdjustmentData: ManualAdjustmentInfoModel;
    selectedCycleDate: string;
    loadingCampuses: boolean;
    showRegenerationDrawer: boolean;
    isBillingDateGone: boolean;
}

@withRouter
export class SchoolLicensesPage extends Component<LicensePageProps, LicensePageStates> {
    @lazyInject(TYPES.ISchoolService)
    schoolService: ISchoolService;
    @lazyInject(TYPES.IResourceService)
    resourceService: IResourceService;
    @lazyInject(TYPES.IUserService)
    userService: IUserService;
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService;
    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService;
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService;

    isShow = false;
    campusFetchedAt = null;
    gridRef: GridRef;

    constructor(props, context) {
        super(props, context);
        this.toggleDrawerVisibility = this.toggleDrawerVisibility.bind(this);
        this.state = {
            school: {},
            schoolAdmin: undefined,
            schoolTrainer: undefined,
            modalVisible: false,
            campuses: [],
            campusSelected: undefined,
            classes: [],
            selectedClass: undefined,
            cycleAdjustmentData: null,
            origCycleAdjustmentData: null,
            selectedCycleDate: undefined,
            loadingCampuses: false,
            showRegenerationDrawer: false,
            isBillingDateGone: false,
        };
    }

    componentDidMount() {
        const { regionId, schoolId } = GLUtil.pathParse(PathConfig.Schools);
        this.isShow = true;
        this.getSchoolInfo(schoolId);
        // this.setSchoolBillingList();
        this.regionService.getSchoolCycleInvoiceGenerated({ regionId }).then(({ isCurrentInvoiceGenerated }) => {
            this.setState({ isBillingDateGone: isCurrentInvoiceGenerated });
        });
    }
    getSchoolInfo(schoolId) {
        this.schoolService
            .getSchool({ id: schoolId })
            .then((school) => {
                let actions = [];
                let permissionParams = {
                    resourceType: ResourceType.School,
                    resourceKey: schoolId,
                    propagationTo: Propagation.RegionAdmin,
                };
                let params = {
                    resourceId: schoolId,
                    resourceType: InvitationType.schoolAdmin,
                };
                actions.push(
                    this.resourceService.getResourceUserIds(params).then((userIds) => {
                        if (userIds.length == 0) {
                            return new Promise((resolve, reject) => {
                                resolve({ data: [] });
                            });
                        } else {
                            return this.userService.getItemsBy({ ids: userIds });
                        }
                    })
                );
                params = {
                    resourceId: schoolId,
                    resourceType: InvitationType.trainer,
                };
                actions.push(
                    this.resourceService.getResourceUserIds(params).then((userIds) => {
                        if (userIds.length == 0) {
                            return new Promise((resolve, reject) => {
                                resolve({ data: [] });
                            });
                        } else {
                            return this.userService.getItemsBy({ ids: userIds });
                        }
                    })
                );
                Promise.all(actions)
                    .then((res) => {
                        const [admins, trainers] = res;
                        const schoolAdmin = admins.data.length > 0 ? admins.data[0].name : null;
                        const schoolTrainer = trainers.data.length > 0 ? trainers.data[0].name : null;
                        this.setState({
                            school: school,
                            schoolAdmin: schoolAdmin,
                            schoolTrainer: schoolTrainer,
                        });
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    showAdjustmentPopup = (e: any) => {
        e.preventDefault();
        // fetch school campuses
        if (!this.state.campuses.length || (this.campusFetchedAt && moment(this.campusFetchedAt).diff(moment(Date.now()), "second") > 600)) {
            this.campusFetchedAt = Date.now();
            this.setState({ modalVisible: true, loadingCampuses: true }, () => {
                this.schoolService.getAccessibleCampuses(GLUtil.pathParse(PathConfig.Schools).schoolId).then((campuses) => {
                    this.setState({ campuses, loadingCampuses: false });
                });
            });
        } else {
            this.setState({ modalVisible: true });
        }
    };

    toggleDrawerVisibility() {
        this.setState({
            showRegenerationDrawer: !this.state.showRegenerationDrawer,
        });
    }

    campusChanged = (campusSelected) => {
        this.setState({ campusSelected });
        // fetch classes for campus
        this.schoolClassService.getAccessibleClasses(GLUtil.pathParse(PathConfig.Schools).schoolId, campusSelected).then((res) => {
            this.setState({ classes: res.data });
        });
    };

    classChanged = (selectedClass) => {
        this.setState({ selectedClass });
        // fetch next 5 billing cycles
        let query = GLUtil.pathParse(PathConfig.Schools);
        this.licenseService.getSchoolClassManualAdjustments({ regionId: query.regionId, schoolClassId: selectedClass }).then((response) => {
            this.setState({
                cycleAdjustmentData: this.formatDateForLicenceAdjustment(response),
                origCycleAdjustmentData: response,
            });
        });
    };

    formatDateForLicenceAdjustment = (data: ManualAdjustmentInfoModel) => {
        if (!data) {
            return data;
        }
        let dataLocal = JSON.parse(JSON.stringify(data));
        dataLocal.adjustments = dataLocal.adjustments.map((a) => {
            a.billingDate = DateHelper.toLocalDate(moment(a.billingDate.substring(0, 10), AdjustmentDateFormat));
            return a;
        });
        return dataLocal;
    };

    saveAdjustments = () => {
        const { cycleAdjustmentData, origCycleAdjustmentData } = this.state;

        if (this.state.selectedCycleDate) {

            let formattedcycleAdjustmentData = {
                ...cycleAdjustmentData,
                adjustments: [...cycleAdjustmentData.adjustments].map((r, index) => {
                    r.billingDate = origCycleAdjustmentData.adjustments[index].billingDate;
                    return r;
                }),
                schoolClassId: this.state.selectedClass,
            };
            // hide modal
            this.resetStateAdjustmentData();
            // save data and retrieve the new data
            this.licenseService
                .saveSchoolClassManualAdjustments(formattedcycleAdjustmentData)
                .then((result) => {
                    if (result.processResults.find((res) => res === UpdateAdjustmentResultCode.AdjustmentUpdated)) {
                        message.success(fmtMsg({ id: SchoolLocale.EditClassAdjustmentSaveSuccess }));
                    }
                    if (result.processResults.find((res) => res === UpdateAdjustmentResultCode.InvoiceNotFound)) {
                        /** Reasons:
                         * 1. Class created after invoice generation.
                         * 2. Billing day is pushed back before invoice generation to a date before today.
                         * 3. Billing start date is pushed after billing date.
                         * 4. Rare: Timezone is changed (only in case when billing start day and billing date are same)
                         * 5. More rare: Invoice did not ran for the school class for some wierd reason.
                         */
                        message.warning(fmtMsg({ id: SchoolLocale.SaveAdjustmentInvoiceUnavailable }));
                    }
                })
                .catch((e) => message.error(fmtMsg({ id: SchoolLocale.EditClassAdjustmentSaveFail })));
        }
    };

    resetStateAdjustmentData = () => {
        this.setState({
            modalVisible: false,
            campusSelected: undefined,
            classes: [],
            selectedClass: undefined,
            cycleAdjustmentData: null,
            origCycleAdjustmentData: null,
            selectedCycleDate: undefined,
        });
    };

    adjustmentModal = () => {
        const {
            modalVisible,
            campuses,
            campusSelected,
            classes,
            selectedClass,
            selectedCycleDate,
            cycleAdjustmentData,
            loadingCampuses,
        } = this.state;
        const selectedCycleIndex =
            !selectedCycleDate ? 0 : cycleAdjustmentData.adjustments.findIndex((e) => e.billingDate === selectedCycleDate);

        const currentCycleAdjustment = cycleAdjustmentData
            ? cycleAdjustmentData.adjustments[selectedCycleIndex]
            : { shouldSkip: false, adjustment: 0 };

        const handleCycleChange = (selectedCycleDate: string) => {
            this.setState({ selectedCycleDate });
        };

        const handleAdjusmentChange = (value: number) => {
            const newCycleAdjusments = [...cycleAdjustmentData.adjustments].map((a, index) => {
                if (index === selectedCycleIndex) {
                    a.adjustment = value;
                }
                return a;
            });
            this.setState({ cycleAdjustmentData: { ...cycleAdjustmentData, adjustments: newCycleAdjusments } });
        };

        const noteChanged = ({ target: { value } }) => {
            const newCycleAdjusments = [...cycleAdjustmentData.adjustments].map((a, index) => {
                if (index === selectedCycleIndex) {
                    a.note = value;
                }
                return a;
            });
            this.setState({ cycleAdjustmentData: { ...cycleAdjustmentData, adjustments: newCycleAdjusments } });
        };

        const getSkipNote = (currentAdj, index) => {
            if (index === 0) {
                return currentAdj.shouldSkip && !cycleAdjustmentData.isInvoiceGenerated ? fmtMsg({ id: SchoolLocale.InvoiceUnaffectedNote }) : null;
            } else {
                return currentAdj.shouldSkip ? fmtMsg({ id: SchoolLocale.InvoiceUnaffectedNote }) : null;
            }
        };

        return (
            <Modal
                visible={modalVisible}
                onCancel={this.resetStateAdjustmentData}
                className="asmod"
                title={fmtMsg({ id: SchoolLocale.EditClassAdjustLicence })}
                onOk={this.saveAdjustments}
                okText={fmtMsg({ id: GSAdminLocale.ButtonSave })}
                okButtonProps={{
                    disabled:
                     !selectedCycleDate || (cycleAdjustmentData &&
                        cycleAdjustmentData.adjustments &&
                        cycleAdjustmentData.adjustments.some((x) => x.adjustment > 1000 || x.adjustment < -1000)),
                    className: "primary",
                }}
            >
                {loadingCampuses && <Loading visible={true}></Loading>}
                {!loadingCampuses && (
                    <>
                        <Row>
                            <Col md={8}>{fmtMsg({ id: SchoolLocale.ClassNewSourceCampusSelect })}</Col>
                            <Col>
                                <Select
                                    className="asmod__select"
                                    placeholder={fmtMsg({ id: SchoolLocale.SchoolLicenseHistorySelectCampus })}
                                    value={campusSelected}
                                    disabled={!campuses.length}
                                    onChange={this.campusChanged}
                                >
                                    {campuses.map((c) => (
                                        <Select.Option value={c.id} key={c.id}>
                                            {c.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>{fmtMsg({ id: SchoolLocale.ClassNewDestinationClassSelect })}</Col>
                            <Col>
                                <Select
                                    className="asmod__select"
                                    placeholder={fmtMsg({ id: SchoolLocale.UnitPlanCopyTitle })}
                                    value={selectedClass}
                                    disabled={!classes.length}
                                    onChange={this.classChanged}
                                >
                                    {classes.map((c) => (
                                        <Select.Option value={c.id} key={c.id}>
                                            {c.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>{fmtMsg({ id: SchoolLocale.EditClassBillingDate })}</Col>
                            <Col>
                                <Select
                                    className="asmod__select"
                                    disabled={!selectedClass}
                                    onChange={handleCycleChange}
                                    value={selectedCycleDate}
                                    placeholder={fmtMsg({ id: SchoolLocale.UnitPlanAdjustLicenseTitle })}
                                >
                                    {cycleAdjustmentData &&
                                        cycleAdjustmentData.adjustments.map((c, index) => (
                                            <Select.Option key={index} value={c.billingDate}>
                                                {c.billingDate}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>{fmtMsg({ id: SchoolLocale.StudetnInviteCnt })}</Col>
                            <Col>
                                <InputNumber
                                    className="asmod__number"
                                    disabled={!selectedCycleDate}
                                    value={selectedCycleDate && cycleAdjustmentData.adjustments[selectedCycleIndex].adjustment}
                                    onChange={handleAdjusmentChange}
                                />
                            </Col>
                        </Row>
                        <Row>{fmtMsg({ id: SchoolLocale.LicenseHistoryNotes })}</Row>
                        <Row>
                            <Input.TextArea
                                maxLength={200}
                                disabled={!selectedCycleDate}
                                value={cycleAdjustmentData && cycleAdjustmentData.adjustments[selectedCycleIndex].note}
                                onChange={noteChanged}
                            ></Input.TextArea>
                        </Row>
                        {
                            <>
                                <Row className="asmod__error">
                                    {currentCycleAdjustment.adjustment > 1000 || currentCycleAdjustment.adjustment < -1000
                                        ? fmtMsg({ id: SchoolLocale.LicenseAdjustmentValidCountMsg })
                                        : null}
                                </Row>
                                <Row className="asmod__error asmod__warning">{getSkipNote(currentCycleAdjustment, selectedCycleIndex)}</Row>
                            </>
                        }
                    </>
                )}
            </Modal>
        );
    };

    render() {
        const { regionId, schoolId } = GLUtil.pathParse(PathConfig.Schools);
        return (
            <Container className="license-list">
                <MainTitle title={GSAdminLocale.SchoolLicenseTitle} />
                <div>
                    {GLGlobal.isActionValid(GSAdminAction.ClassManualAdjustment) && (
                        <Row>
                            <Col className="page-content__license-end">
                                <a className="page-content__license-end__link" onClick={(e) => this.showAdjustmentPopup(e)}>
                                    {fmtMsg({ id: SchoolLocale.EditClassAdjustLicence })}
                                </a>
                                {this.state.isBillingDateGone && (
                                    <>
                                        <a className="page-content__license-end__link" onClick={this.toggleDrawerVisibility}>
                                            {fmtMsg({ id: SchoolLocale.RegenerateInvoiceText })}
                                        </a>
                                        <RegenerateInvoice
                                            visible={this.state.showRegenerationDrawer}
                                            onClose={this.toggleDrawerVisibility}
                                            destroyOnClose
                                            maskClosable
                                            regionId={regionId}
                                            schoolId={schoolId}
                                            onSaveCallback={() => this.gridRef && this.gridRef.reload()}
                                        ></RegenerateInvoice>
                                    </>
                                )}
                            </Col>
                        </Row>
                    )}
                    <BillingTableComponent
                        history={this.props.history}
                        containerPath={PathConfig.SchoolLicenses}
                        gridRef={(ref) => {
                            this.gridRef = ref;
                        }}
                    />
                </div>
                {this.adjustmentModal()}
            </Container>
        );
    }
}
