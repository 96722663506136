import React, { useState, useEffect } from "react";
import { Drawer, Row, Col, Button, message } from "antd-min";
import { DrawerProps } from "antd/lib/drawer";
import { ColumnProps } from "antd/lib/table";
import { InvoiceRuntimeRegenerationModel, ISchoolLicenseService } from "@app/service/admin/license";
import { useService } from "@app/hooks";
import { TYPES, fmtMsg, CONSTS } from "@app/util";
import { Loading, TableWithPerfectScroll, SecondaryTitle } from "@app/components";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { Link } from "react-router-dom";
import { SchoolPathConfig } from "@app/config/pathconfig";
import { GLUtil } from "gl-commonui";
import { WijmoGrid } from "@app/components/grid";
import "./regenerate-invoice.less";

const { Grid, Column } = WijmoGrid;


export enum RegenerateDisableColumns {
    Campus = "campus",
    Class = "schoolClass",
}

interface RegenerateInvoiceProps extends DrawerProps {
    regionId: string;
    schoolId: string;
    campusId?: string;
    schoolClassId?: string;
    onSaveCallback?: () => void;
    disableColumns?: RegenerateDisableColumns[];
}

export const RegenerateInvoice = (props: RegenerateInvoiceProps) => {
    const { regionId, schoolId, campusId, schoolClassId, onSaveCallback, visible, width, disableColumns, ...drawerProps } = props;
    const [regenerations, setRegenerations] = useState([] as InvoiceRuntimeRegenerationModel[]);
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const [maxHeight, setMaxHeight] = useState(window.innerHeight - 200);
    const [drawerWidth, setDrawerWidth] = useState(width || (window.innerWidth * 0.8));
    const service = useService<ISchoolLicenseService>(TYPES.ISchoolLicenseService);

    useEffect(() => {
        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    const onResize = () => {
        setMaxHeight(window.innerHeight - 200);
        setDrawerWidth((window.innerWidth * 0.8));
    }

    useEffect(() => {
        if (visible && !loading) {
            getRegenerations();
        }
    }, [visible]);

    const getRegenerations = () => {
        setLoading(true);
        setLoadingText(fmtMsg({ id: SchoolLocale.RegenerateInvoiceLoading }));
        service.getRuntimeRegeneration({ regionId, schoolId, schoolClassId: schoolClassId || CONSTS.EmptyGuid })
            .then(response => {
                setLoading(false);
                setRegenerations(response);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const getColumns = () => {
        let columns = [
            {
                title: fmtMsg({ id: SchoolLocale.LicenseHistoryCampus }),
                dataIndex: "campus",
                key: "campus",
                width: "20%",
                render: (text, record) => {
                    const path = GLUtil.pathStringify(SchoolPathConfig.CampusEdit, { regionId, schoolId, campusId: campusId || record.campusId });
                    return <a className="regenerate-invoice__link" href={path}>{text}</a>
                }
            },
            {
                title: fmtMsg({ id: SchoolLocale.LicenseHistoryClass }),
                dataIndex: "schoolClass",
                key: "schoolClass",
                width: "20%",
                render: (text, record) => {
                    const path = GLUtil.pathStringify(SchoolPathConfig.ClassEdit, { regionId, schoolId, campusId: campusId || record.campusId, classId: schoolClassId || record.schoolClassId });
                    return <a className="regenerate-invoice__link" href={path}>{text}</a>
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.RegenerateInvoiceActualBefore }),
                dataIndex: "actualBefore",
                key: "actualBefore",
                width: "10%",
            },
            {
                title: fmtMsg({ id: SchoolLocale.RegenerateInvoiceAdjustmentBefore }),
                dataIndex: "manualAdjustmentBefore",
                key: "manualAdjustmentBefore",
                width: "10%",
                render: (_, { manualAdjustmentBefore, autoAdjustmentBefore }) => manualAdjustmentBefore + autoAdjustmentBefore
            },
            {
                title: fmtMsg({ id: SchoolLocale.RegenerateInvoiceTotalBefore }),
                dataIndex: "totalBilledBefore",
                key: "totalBilledBefore",
                width: "10%",
            },
            {
                title: fmtMsg({ id: SchoolLocale.RegenerateInvoiceActualAfter }),
                dataIndex: "actualAfter",
                key: "actualAfter",
                width: "10%",
            },
            {
                title: fmtMsg({ id: SchoolLocale.RegenerateInvoiceAdjustmentAfter }),
                dataIndex: "manualAdjustmentAfter",
                key: "manualAdjustmentAfter",
                width: "10%",
                render: (_, { manualAdjustmentAfter, autoAdjustmentAfter }) => manualAdjustmentAfter + autoAdjustmentAfter
            },
            {
                title: fmtMsg({ id: SchoolLocale.RegenerateInvoiceTotalAfter }),
                dataIndex: "totalBilledAfter",
                key: "totalBilledAfter",
                width: "10%",
            },
        ];
        const columnsToDisable = disableColumns || [];

        return columns.filter(column => !columnsToDisable.some(disable => disable === column.key));
    }

    const onCancel = (e) => {
        drawerProps.onClose && drawerProps.onClose(e);
    }

    const onSave = (e) => {
        setLoadingText(fmtMsg({ id: SchoolLocale.RegenerateInvoiceInProgress }));
        setLoading(true);
        service.regenerate({ regionId, schoolId, schoolClassId: (schoolClassId || CONSTS.EmptyGuid) })
            .then(() => {
                setLoading(false);
                setLoadingText(fmtMsg({ id: SchoolLocale.RegenerateInvoiceLoading }));
                message.success(fmtMsg({ id: SchoolLocale.RegenerateInvoiceSuccessMsg }));
                onSaveCallback && onSaveCallback();
                drawerProps.onClose && drawerProps.onClose(e);
                getRegenerations();
            })
            .catch(() => {
                setLoading(false);
                setLoadingText(fmtMsg({ id: SchoolLocale.RegenerateInvoiceLoading }));
                message.error(fmtMsg({ id: SchoolLocale.RegenerateInvoiceFailureMsg }));
            });
    }

    const shouldDisableSave = (data: InvoiceRuntimeRegenerationModel[]) => {
        return data.filter(item =>
            item.actualAfter === item.actualBefore
            && item.autoAdjustmentAfter === item.autoAdjustmentBefore
            && item.manualAdjustmentAfter === item.manualAdjustmentBefore
            && item.totalBilledAfter === item.totalBilledBefore).length === data.length;
    }

    return (
        <Drawer
            className="regenerate-invoice"
            visible={visible}
            width={drawerWidth}
            {...drawerProps}>
            {
                loading
                    ? <Loading visible={loading} text={loadingText} iconClassName="regenerate-invoice__loader" ></Loading>
                    : <div className="regenerate-invoice__wrapper">
                        <SecondaryTitle plain="Regenerate" />
                        <Grid
                            itemsSource={regenerations}
                            pagination={false} 
                            loading={loading} 
                            stickyHeaders={false}
                        >
                            {
                                getColumns().map( column => {
                                    return <Column 
                                        header={column.title}
                                        binding={column.dataIndex}
                                        render={column.render}
                                        key={column.key}
                                    />
                                })
                            }
                        </Grid>
                        <Row className="portal-theme regenerate-invoice__wrapper__btn-row" type="flex" justify="end" gutter={10}>
                            <Col>
                                <Button disabled={shouldDisableSave(regenerations)} onClick={onSave} type="primary">{fmtMsg({ id: GSAdminLocale.ButtonSave })}</Button>
                            </Col>
                            <Col>
                                <Button onClick={onCancel}>{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</Button>
                            </Col>
                        </Row>
                    </div>
            }
        </Drawer>

    );
}